@tailwind base;
@tailwind components;
@tailwind utilities;


.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@layer base {
  body {
    @apply dark:bg-gray-900;
  }
}

@layer components {
  .my-custom-pagination {
    @apply bg-white dark:text-white text-gray-700 dark:bg-gray-700 flex flex-nowrap gap-2 sticky inset-0 overflow-auto px-4 z-[100]; /* add safari */ /* z-100 */
  }
  .my-custom-pagination > span {
    @apply bg-gray-100 dark:bg-gray-900;
  }
}
.swiper-pagination-bullet {
  padding: 0.7rem 1.5rem !important;
  height: 2.5rem !important;
  margin: 1rem 0 !important;
  width: auto !important;
  white-space: nowrap;
  border-radius: 10000px !important;
  display: flex !important;
  flex-direction: row-reverse;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  /* cursor: none !important; */
  opacity: 1 !important;
  font-size: small;
  font-weight: 600;
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: hsl(180, 100%, 25%) !important;
}

.p-4 {
  padding: 0.4rem 1rem 0.5rem 1rem;
}

.eax {
  margin-left: 17px;
  margin-top: 17px;
  cursor: pointer;
  height: 24px !important;
  width: 24px !important;
  transition: all 0.3s ease;
}

input, textarea, button, select, div, .eax, .class, p, a {
  -webkit-tap-highlight-color: transparent;
}

.my-custom-pagination  {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 1%), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
